import React from 'react';

function Home() {
  return (
    <div>
      <h1>Bem-vindo ao StradData</h1>
      <p>Este é o blog sobre dados e tecnologia.</p>
    </div>
  );
}

export default Home;
