import React from 'react';

function Contact() {
  return (
    <div>
      <h1>Contato</h1>
      <p>Entre em contato pelo e-mail: bonihen@straddata.com</p>
    </div>
  );
}

export default Contact;
